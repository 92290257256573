<template>
    <div id="NotFound">
        404
    </div>
</template>

<script>
export default {
    name:'NotFound'
}
</script>

<style lang="less" scoped>
#NotFound{
    height: 100%;
    line-height: 50vh;
    text-align: center;
    font-size: 30px;
}
</style>